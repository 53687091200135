import BlockContent from "@sanity/block-content-to-react";
import YouTube from "react-youtube";
import getYouTubeId from "get-youtube-id";

export default function Content({ content, className = "", ...rest }) {
  if (!content) return null;

  const serializers = {
    types: {
      youtube: ({ node }) => {
        const { url } = node;
        const id = getYouTubeId(url);
        return <YouTube videoId={id} />;
      },
    },
  };

  return (
    <BlockContent
      renderContainerOnSingleChild
      blocks={content}
      serializers={serializers}
      projectId={process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}
      dataset={process.env.NEXT_PUBLIC_SANITY_DATASET}
      className={className}
      {...rest}
    />
  );
}
